<template>
	<main class="page-container" id="join-test-main" onsubmit="">
    <form method="post" action="https://dev-sso.valuebuy.kr/sso/RequestAuth.jsp">
<!--    <form method="post" action="https://sso.valuebuy.kr/sso/RequestAuth.jsp">-->
      아이디 : <input type="text" id="loginId" name="loginId">
      비밀번호 : <input type="text" id="loginPw" name="loginPw">
      <button type="submit" class="btn btn-md btn-login">로그인</button>
    </form>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from "axios";

export default {
	name: 'JoinGeneralComplete',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth', 'checkMeInfo']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('join', ['userNm']),
	},
	watch: {},
	data() {
    return {
      formData: {
        loginId: '',
        loginPw: ''
      }
    };
  },
	mounted() {},
	methods: {
    handleSubmit() {
      const url = 'http://223.255.205.139/sso/RequestAuth.jsp'
      console.log('Form Data:', this.formData);
      console.log('fetch Call Start');
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers here
        },
        body: JSON.stringify(this.formData)
      })
    },
  },
	destroyed() {},
};
</script>
